import { getLogger } from 'aurelia-logging';
import { autoinject } from 'aurelia-framework';
import { BaseViewModel } from '../../../../../common';
import {
  getNewId,
  toObject,
  emptyArray,
  validateEmail,
  removeNoneFromArray,
  isNone,
  mergeObjects,
  ensureNumber
} from '../../../../../../utility';
import { rootState } from '../../../../../../reducers';
import { alarmStatistics, alarmOperators } from '../../../../../../models';
import {
  toggleExpandMenuOnEntity,
  alarmPropertyChanged,
  toggleAddRecipientDialog,
  changeRecipientType,
  addRecipient,
  externalRecipientAddressChanged,
  editExternalRecipient,
  removeRecipient,
  recipientPropertyChanged,
  updateRecipient
} from '../../../../../../actions';
import { menuIsOpenForEntity } from '../../../../../../types';
import {
  IAlarm,
  ISiteChannel,
  IUser,
  IAlarmRecipient
} from '../../../../../../interfaces';
import {
  ChannelService,
  AlarmService,
  UserService
} from '../../../../../../services';
import { routeNames } from '../../../../../../config';
import { Router } from 'aurelia-router';
import './sitedetailsalarmsedit.css';

interface IAlarmUser {
  alarmRecipientId: number;

  email?: string;
  user?: IUser;
}

interface IAlarmSiteChannel extends ISiteChannel {
  displayValue: string;
  iconName: string | undefined;
}

interface ISiteDetailsAlarmsEditState {
  siteId: number;
  alarmId: number;

  alarmStatisticsMap: {};
  alarmStatistics: Array<{ id: number; value: string }>;
  alarmOperators: Array<{ operator: string; resetOperator: string }>;

  currentlyEditing?: IAlarm;

  recipients: {
    original: IAlarmRecipient[];
    internal: IAlarmUser[];
    external: IAlarmUser[];
  };

  siteChannelsMap: {};
  siteChannels: IAlarmSiteChannel[];

  menuIsOpenForEntity?: menuIsOpenForEntity;

  addRecipientDialogOpen: boolean;
  addRecipientType?: string;

  externalRecipientAddress?: string;
  invalidExternalRecipientAddress: boolean;

  currentlyEditingRecipient?: IAlarmRecipient;

  userSearch: {
    query?: string;
    isSearching: boolean;
    results: IUser[];
  };

  isMobile: boolean;

  alarmValidationErrors: Record<string, string[]> | undefined;
}

const mapInternalUsers = (
  recipients: IAlarmRecipient[],
  userService: UserService
): IAlarmUser[] => {

  const userIdsWithTokens = recipients
    .filter(r => !isNone(r.userId))
    .map(r => ({ id: r.userId, token: r.userIdToken }));
  const userIds = removeNoneFromArray(userIdsWithTokens.map(it => it.id));
  const tokens = removeNoneFromArray(userIdsWithTokens.map(it => it.token));
  const usersMap = toObject(
    userService.getUsers(userIds, tokens).getEntityOrDefault(emptyArray),
    u => u.userId
  );

  return recipients.map(r => ({
    alarmRecipientId: r.alarmRecipientId,
    user: usersMap[r.userId!]
  }));
};

const mapExternalUsers = (recipients: IAlarmRecipient[]) =>
  recipients.map(r => ({
    alarmRecipientId: r.alarmRecipientId,
    externalEmail: r.externalEmail
  }));

const mapRecipients = (
  recipients: IAlarmRecipient[],
  userService: UserService
): {
  original: IAlarmRecipient[];
  internal: IAlarmUser[];
  external: IAlarmUser[];
} => {
  const internal = removeNoneFromArray(recipients.filter(ar => ar.userId));
  const external = removeNoneFromArray(
    recipients.filter(ar => isNone(ar.userId))
  );

  return {
    original: recipients,
    internal: mapInternalUsers(internal, userService),
    external: mapExternalUsers(external)
  };
};

@autoinject()
export class SiteDetailsAlarmsEdit extends BaseViewModel<
  ISiteDetailsAlarmsEditState
> {
  menuForSiteChannelDropdown = getNewId();
  menuForAlarmStatisticsDropdown = getNewId();
  menuForOperatorDropdown = getNewId();

  constructor(
    private router: Router,
    private userService: UserService,
    private alarmService: AlarmService,
    private channelService: ChannelService
  ) {
    super(getLogger('SiteDetailsAlarms'));
  }

  activate({ id, alarmId }: { id: string; alarmId: string | undefined }) {
    if (isNone(id)) return;
    this.attachMapState(
      this.mapState(
        ensureNumber(id),
        isNone(alarmId) ? undefined : ensureNumber(alarmId)
      )
    );
  }

  mapState = (siteId: number, alarmId: number | undefined = 0) => (
    state: rootState
  ): ISiteDetailsAlarmsEditState => {
    const siteChannels: IAlarmSiteChannel[] = this.channelService
      .getSiteChannelsForSite(siteId)
      .getEntityOrDefault(emptyArray)
      .map(siteChannel => ({
        ...siteChannel,
        displayValue: `${siteChannel.alias} (${siteChannel.channelId})`,
        iconName: siteChannel.isParked ? 'parked' : undefined
      }));

    const invalidExternalRecipientAddress = state.sitedetailsAlarms
      .externalRecipientAddress
      ? validateEmail(state.sitedetailsAlarms.externalRecipientAddress)
      : false;

    const siteAlarms = this.alarmService
      .getAlarmsBySiteId(siteId)
      .getEntityOrDefault(emptyArray);

    const alarm = mergeObjects(siteAlarms.find(a => a.alarmId === alarmId));
    const currentlyEditing = mergeObjects(
      alarm,
      state.sitedetailsAlarms.currentlyEditingAlarm
    );

    const existingRecipients =
      alarmId > 0
        ? this.alarmService
            .getAlarmRecipientsForAlarm(siteId, alarmId)
            .getEntityOrDefault(emptyArray)
        : emptyArray;

    const recipients =
      state.sitedetailsAlarms.currentlyEditingAlarmRecipients !== undefined
        ? state.sitedetailsAlarms.currentlyEditingAlarmRecipients
        : existingRecipients;

    return {
      siteId,
      alarmId,
      alarmStatisticsMap: toObject(alarmStatistics, a => a.id),
      alarmStatistics,
      alarmOperators,

      addRecipientDialogOpen: state.sitedetailsAlarms.addRecipientDialogOpen,
      addRecipientType: state.sitedetailsAlarms.addRecipientType,

      externalRecipientAddress:
        state.sitedetailsAlarms.externalRecipientAddress,
      invalidExternalRecipientAddress,

      currentlyEditing,
      recipients: mapRecipients(recipients, this.userService),

      siteChannelsMap: toObject(siteChannels, sc => sc.siteChannelId),
      siteChannels,

      menuIsOpenForEntity: state.application.menuIsOpenForEntity,

      currentlyEditingRecipient:
        state.sitedetailsAlarms.currentlyEditingRecipient,

      userSearch: {
        isSearching: state.sitedetailsAlarms.isSearching,
        query: state.sitedetailsAlarms.userSearchQuery,
        results: state.sitedetailsAlarms.searchResults
      },

      isMobile: state.device.screenSize === 'mobile',

      alarmValidationErrors: state.sitedetailsAlarms.alarmValidationErrors
    };
  };

  saveChanges = async () => {
    if(!this.state.currentlyEditing?.alarmId){
      await this.createAlarm(this.state.currentlyEditing!, this.state.recipients.original)
    }
    else if (this.state.currentlyEditing.alarmId > 0) {
      await this.updateAlarm(this.state.currentlyEditing!, this.state.recipients.original)
    }    
  }

  createAlarm = async (alarm: IAlarm, recipients: IAlarmRecipient[]) => {
    const createdAlarm = await this.alarmService.saveAlarm(
      this.state.siteId,
      alarm,
      recipients
    );
    if (createdAlarm && createdAlarm.alarmId > 0)
      this.router.navigateToRoute(
        routeNames.sitedetailsAlarms,
        { alarmId: createdAlarm.alarmId },
        { replace: true }
      );
  };

  updateAlarm = async (alarm: IAlarm, recipients: IAlarmRecipient[]) => {
    await this.alarmService.updateAlarm(this.state.siteId, alarm, recipients);
    this.router.navigateBack();
  };

  findUser = (query: string) => {
    if (query.length > 0)
      this.alarmService.findUser(this.state.siteId, 0, query);
  };

  cancelEdit = () => {
    this.router.navigateBack();
  };

  dispatchUpdateRecipient = (recipient: IAlarmRecipient) =>
    this.dispatch(
      updateRecipient({
        recipient,
        existingRecipients: this.state.recipients.original
      })
    );

  dispatchRemoveRecipient = (recipient: IAlarmRecipient) =>
    this.dispatch(
      removeRecipient({
        recipient,
        existingRecipients: this.state.recipients.original
      })
    );

  addInternalRecipient = (user: IUser) =>
    this.dispatchAddRecipient(
      { alarmRecipientId: 0, alarmId: 0, userId: user.userId },
      user
    );

  addExternalRecipient = (value: string) =>
    this.dispatchAddRecipient({
      alarmRecipientId: 0,
      alarmId: 0,
      externalEmail: value
    });

  dispatchAddRecipient = (recipient: IAlarmRecipient, user?: IUser) =>
    this.dispatch(
      addRecipient({
        recipient,
        user,
        existingRecipients: this.state.recipients.original
      })
    );

  dispatchEditExternalRecipient = (recipient: IAlarmRecipient) =>
    this.dispatch(editExternalRecipient(recipient));

  dispatchExternalRecipientAddressChanged = (value: string) =>
    this.dispatch(externalRecipientAddressChanged(value));

  dispatchToggleExpandMenuOnEntity = (menuItemId: number) =>
    this.dispatch(toggleExpandMenuOnEntity(menuItemId));

  dispatchLimitChangedOnAlarm = (
    newLimit: number
  ) => {
    this.dispatchPropertyChangedOnAlarm('limit', newLimit);
    if (this.state.currentlyEditing && this.state.currentlyEditing.operator == '='){      
      this.dispatchPropertyChangedOnAlarm('resetLimit', newLimit); // When the operator is '=' resetLimit should be the same as limit.
    }
  };

  dispatchOperatorChangedOnAlarm = (
    newOperator: string
  ) => {
    this.dispatchPropertyChangedOnAlarm('operator', newOperator);
    if (newOperator == "=" && this.state.currentlyEditing) {
      this.dispatchPropertyChangedOnAlarm('resetLimit', this.state.currentlyEditing.limit); // When the operator is '=' resetLimit should be the same as limit.
    }
  };

  dispatchPropertyChangedOnAlarm = (
    name: keyof IAlarm,
    value: number | string
  ) => {
    if (
      this.state.currentlyEditing &&
      this.state.currentlyEditing[name] != value
    )
      this.dispatch(alarmPropertyChanged({ name, value }));
  };

  dispatcPropertyChangedOnRecipient = (
    name: keyof IAlarmRecipient,
    value: string
  ) => {
    if (
      this.state.currentlyEditingRecipient &&
      this.state.currentlyEditingRecipient[name] != value
    )
      this.dispatch(recipientPropertyChanged({ name, value }));
  };

  dispatchToggleAddRecipientDialog = () =>
    this.dispatch(toggleAddRecipientDialog());

  dispatchChangeRecipientType = (type: 'email' | 'user' | undefined) =>
    this.dispatch(changeRecipientType(type));

  validateEmail = (email: string) => validateEmail(email);
}
